import { action, Action } from 'easy-peasy';

interface LayoutModelProps {
    isSideNavOpen: boolean;
    sideNavWidth: string;

    setIsSideNavOpen: Action<LayoutModelProps, boolean>;
    setSideNavWidth: Action<LayoutModelProps, string>;
    toggleOpen: Action<LayoutModelProps>;

}

export const SIDE_NAV_WIDTH = '280px';

const LayoutModel: LayoutModelProps = {
    isSideNavOpen: true,
    sideNavWidth: '280px',

    setIsSideNavOpen: action((state, payload: boolean) => {
        state.isSideNavOpen = payload;
    }),

    toggleOpen: action((state) => {
        state.isSideNavOpen = !state.isSideNavOpen;
    }),

    setSideNavWidth: action((state, payload: string) => {
        state.sideNavWidth = payload;
    }),

};

export default LayoutModel;