import { Box, Stack } from '@mui/system';
import React, { useEffect, MouseEvent, ChangeEvent, useState, useCallback } from 'react';
import { useStoreState, useStoreActions } from '../../store';
import { OpportunitiesListTable } from '../../components/list-table-opportunities';
import { Button, Typography } from '@mui/material';
import { CircularLoader } from '../../components/circular-loader';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import IconButton from '@mui/material/IconButton';
import FiltersDemandSideNav from '../../components/filters-demand-side-nav';
import StateRequestScreen from '../../components/state-request-screen';
import DemandKPIContainer from '../../components/demand-kpi-container';

interface DemandPageProps {

}

const DemandPage: React.FC<DemandPageProps> = () => {

    const requestDemandList = useStoreActions((actions: any) => actions.demand.requestDemandList);
    const setRowsPerPage = useStoreActions((actions: any) => actions.demand.setRowsPerPage);
    const setPage = useStoreActions((actions: any) => actions.demand.setPage);
    const requestFilterOptions = useStoreActions((actions: any) => actions.demand.requestFilterOptions);
    const setLoadingTableState = useStoreActions((actions: any) => actions.demand.setLoadingTableState);
    const setRequestSearchState = useStoreActions((actions: any) => actions.demand.setRequestSearchState);
    const setActiveFilters = useStoreActions((actions: any) => actions.demand.setActiveFilters);

    const demandList = useStoreState((state: any) => state.demand.demandList);
    const requestSearchState = useStoreState((state: any) => state.demand.requestSearchState);
    const loadingTableState = useStoreState((state: any) => state.demand.loadingTableState);
    const filterOptions = useStoreState((state: any) => state.demand.filterOptions);

    const page = useStoreState((state: any) => state.demand.page);
    const rowsPerPage = useStoreState((state: any) => state.demand.rowsPerPage);

    const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

    useEffect(() => {
        requestFilterOptions()
    }, [requestFilterOptions]);

    useEffect(() => {
        setRequestSearchState('loading')
        requestDemandList()
    }, [page, rowsPerPage, requestDemandList, setRequestSearchState]);

    const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage + 1)
    }

    const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(event.target.value)
    }

    const onChangeFilters = useCallback((item: any, endpoint: any) => {
        
        setLoadingTableState('loading')

        const payload = {
            [endpoint]: item,
        }

        setActiveFilters(payload)
        requestDemandList()
        setPage(1)
        
    }, [setPage, requestDemandList, setActiveFilters, setLoadingTableState])

    if (requestSearchState === 'loading') return (
        <Box
            height='100%'
            width='100%'
        >
            <CircularLoader state={requestSearchState} />
        </Box>
    )

    return (
        <Stack
            direction='row'
            sx={{
                justifyContent: "space-between",
                alignItems: "flex-start",
                height: 'calc(100vh - 110px)',
                padding: '10px 0'
            }}
        >

            <FiltersDemandSideNav
                isFiltersOpen={isFiltersOpen}
                filterOptions={filterOptions}
                onChangeFilters={onChangeFilters}
                sx={{
                    minWidth: '25%',
                    margin: '0px 20px',
                }}
            />

            <Box
                sx={{
                    maxHeight: '100%',
                    overflowY: 'auto',
                    backgroundColor: 'white',
                    padding: '0 10px',
                    borderRadius: '10px',
                    width: '100%',
                    height: '100%'
                }}
            >
                <Stack
                    direction='column'
                    height='100%'
                >
                    <Box
                        sx={{
                            margin: '20px 10px'
                        }}
                    >
                        <Typography variant='h4'>
                            Gestión de Demanda
                        </Typography>
                        {
                            (demandList && demandList['TotalRecords']) &&
                                <Typography variant='subtitle1'>
                                    {demandList['TotalRecords'].toLocaleString()} Oportunidades
                                </Typography>
                        }
                        <DemandKPIContainer />
                    </Box>
                    {
                        loadingTableState === 'loading' &&
                        <Box
                            height='100%'
                            width='100%'
                        >
                            <CircularLoader state={loadingTableState} />
                        </Box>
                    }
                    {
                        (loadingTableState !== 'loading' && demandList) &&
                        <Box width='100%'>
                            <Box>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <IconButton
                                        aria-label="filtrar"
                                        sx={{
                                            borderRadius: '5px',
                                            padding: '10px'
                                        }}
                                        onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                                    >
                                        <FilterAltIcon />
                                        <Typography variant='body1' >Filtrar</Typography>
                                    </IconButton>
                                    <IconButton
                                        aria-label="filtrar"
                                        sx={{
                                            borderRadius: '5px',
                                            padding: '10px'
                                        }}
                                    >
                                        <Button variant="text" onClick={() => onChangeFilters(null, 'clear')} > Limpiar Filtros </Button>
                                    </IconButton>
                                </Stack>
                                {
                                    (demandList['data'] && demandList['data'].length > 0) ?
                                        <OpportunitiesListTable
                                            onChangeSort={onChangeFilters}
                                            onPageChange={handlePageChange}
                                            onRowsPerPageChange={handleRowsPerPageChange}
                                            headers={[
                                                { id: 'status', label: 'Estado de Oportunidad' },
                                                { id: 'portal_de_origen', label: 'Origen' },
                                                { id: 'nombre_cliente', label: 'Cliente' },
                                                { id: 'id_oportunidad', label: 'ID Oportunidad' },
                                                { id: 'fecha_publicacion', label: 'Fecha Solicitud' },
                                                { id: 'fecha_cierre', label: 'Fecha Vencimiento' },
                                                { id: 'Cantidad_sku', label: 'Total SKU' },
                                                { id: 'valued_potencial', label: 'Potencial Valorizado' },
                                                { id: 'number_opportunity_sap', label: 'N° Contización SAP' },
                                            ]}
                                            items={demandList['data']}
                                            count={demandList['TotalRecords']}
                                            page={page - 1}
                                            rowsPerPage={rowsPerPage}
                                        />
                                    : <Box width='50%' margin='auto'>
                                        <StateRequestScreen status='error' text='No hay resultados para mostrar' />
                                    </Box>
                                    
                                }
                            </Box>
                        </Box>
                    }
                </Stack>

            </Box>
        </Stack>
    );
};

export default DemandPage;

