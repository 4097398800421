import { FC } from 'react'
import { Box } from '@mui/system'
import { Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

interface StockTableProps {
    readonly items: Array<Record<string, null>> | null
}

const StockTable: FC<StockTableProps> = ({ items }) => {

    if (!items || !Array.isArray(items) || items.length === 0) {
        return (
            <Box>
                <Alert severity='warning'> Stock No Disponible </Alert>
            </Box>
        )
    }

    return (
        <TableContainer sx={{maxHeight: '400px', overflow: 'auto'}} component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre Bodega</TableCell>
                        <TableCell>Código Bodega</TableCell>
                        <TableCell>SOH Real</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        items.map((row, idx) => (
                            <TableRow
                                key={`${row.nombre_bodega}-${idx}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.nombre_bodega}
                                </TableCell>
                                <TableCell>{row.codigo_bodega}</TableCell>
                                <TableCell>{row.SOH_real}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


export default StockTable
