import type { FC } from 'react';
import logo from '../assets/allconect-full.png';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

export const Logo: FC = () => {
  const navigate = useNavigate();

  return (
    <Button
      disableTouchRipple
      disableElevation
      disableRipple
      sx={
        {
          width: '100%',
          height: '100%',
          padding: 0,
          '&:hover': {
            backgroundColor: 'white'
          }
        }
      }
      onClick={() => navigate('/maestra-productos')}
    >
      <img src={logo} alt="Logo" style={{ width: '100%' }} />
    </Button>
  );
};
