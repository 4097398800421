import type { ChangeEvent, FC, MouseEvent } from 'react';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import type { Product } from '../types/product';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Rating, Checkbox } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useStoreActions } from '../store';
import parseCurrency from '../utils/parseCurrency';

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string;
    headCells: Array<{ id: string, label: string }>;
}
type Order = 'asc' | 'desc';

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } =
        props;
    const createSortHandler =
        (property: any) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead
            sx={{
                '.MuiTableCell-root': {
                    backgroundColor: '#ef3234',
                    color: 'white',
                    '.Mui-active': {
                        backgroundColor: '#ef3234',
                        color: 'white',
                        fontWeight: 'bold',
                        '.MuiTableSortLabel-icon': {
                            color: 'white',
                        },
                    },
                },
                '.MuiTableSortLabel-root': {
                    '&:hover': {
                        color: 'white',
                        '.MuiTableSortLabel-icon': {
                            opacity: '1',
                        },
                    }
                }
            }}
        >
            <TableRow>
                <TableCell></TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            backgroundColor: 'red',
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={createSortHandler(headCell.id)}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface SelectMatchListTableProps {
    count?: number;
    items?: Array<any>;
    onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    page?: number;
    rowsPerPage?: number;
    headers: Array<{ id: string, label: string }>;
    itemDetail: Record<string, any>
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort<T>(array: Product[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const SelectMatchListTable: FC<SelectMatchListTableProps> = (props) => {
    const {
        count = 10,
        items = [],
        onPageChange = () => { },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 10,
        headers = [],
        itemDetail
    } = props;

    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<any>('');

    const setNewMatch = useStoreActions((actions: any) => actions.opportunityDetail.setNewMatch);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        console.log(property)
    };

    const visibleRows = useMemo(
        () =>
            stableSort(items, getComparator(order, orderBy)),
        [order, orderBy, items],
    );

    const handleChangeMatch = (match_item: Record<string, any>) => {
        const data = {
            index: itemDetail['index'],
            newItemCode_match_sugerido: match_item['ItemCode'],
            newItemName_match_sugerido: match_item['ItemName'],
            newNombreProveedor_match: match_item['nombre_proveedor'],
            newCluster_match: match_item['cluster'],
            newSimilitudCoseno_match: match_item['similitud_coseno'],
            newScorePriorizacion_match: match_item['score_priorizacion'],
            newEstimatedCost: match_item['estimated_cost'],
            newSellingPrice: match_item['selling_price'],
            newCategoryRotation: match_item['categoria_rotacion'],
            newStock: match_item['Available_clds'],
        }

        setNewMatch(data)
    }

    return (
        <Box>
            <Table
                sx={{
                    // minWidth: 750,
                    border: '1px solid #f0f0f0',
                }}
                aria-labelledby="tableTitle"
                size='medium'
            >
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headers}
                />

                <TableBody>
                    {visibleRows.map((row, index) => {
                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                            >
                                <TableCell>
                                    <Checkbox
                                        key={`chk-${index}`}
                                        aria-label='Checkbox demo'
                                        disableRipple
                                        checked={row['ItemCode'] === itemDetail['ItemCode_match_sugerido'] && row['ItemName'] === itemDetail['ItemName_match_sugerido']}
                                        onClick={() => handleChangeMatch(row)}
                                    />
                                </TableCell>
                                <TableCell align="left">{row['ItemCode']}</TableCell>
                                <TableCell align="left">{row['ItemName']}</TableCell>
                                <TableCell align="left">{row['nombre_proveedor']}</TableCell>
                                <TableCell align="left">{row['categoria_rotacion']}</TableCell>
                                <TableCell align="left">{row['Available_clds']}</TableCell>
                                <TableCell align="left">
                                    {
                                        row["estimated_cost"] !== null ? parseCurrency(row["estimated_cost"]) : '-'
                                    }
                                </TableCell>
                                <TableCell align="left">
                                    {
                                        row["selling_price"] !== null ? parseCurrency(row["selling_price"]) : '-'
                                    }
                                </TableCell>
                                <TableCell align="left">
                                    <Rating
                                        name="text-feedback"
                                        value={parseInt(row['cluster'].toString()) + 1}
                                        readOnly
                                        precision={1}
                                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                        max={5}
                                    />
                                </TableCell>
                                <TableCell align="left">{row['similitud_coseno'] ? (100 * parseFloat(row['similitud_coseno'].toString())).toFixed(2) + '%' : ''}</TableCell>
                                <TableCell align="left">{row['score_priorizacion'] ? (100 * parseFloat(row['score_priorizacion'].toString())).toFixed(2) + '%' : ''}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Box >
    );
};

SelectMatchListTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number
};
