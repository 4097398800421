import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Fab, FormGroup, TextField } from "@mui/material"
import { Box, BoxProps, Stack } from "@mui/system"
import { FC, useState } from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import parseStatus, { getStatusID, OpportunityStatusType } from '../utils/parseStatus';
import DatePickerRange from './date-picker-range';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface AccordionFiltersProps {
    readonly children: React.ReactNode,
    readonly title: string,
    readonly endpoint: string,
}

const AccordionFilters: FC<AccordionFiltersProps> = ({ children, title, endpoint }) => {

    return (
        <Accordion id="accordion_no_header_padding">
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    maxHeight: '300px',
                    overflowY: 'auto'
                }}
            >
                <FormGroup>
                    {children}
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    )
}

interface FiltersDemandSideNavProps {
    readonly isFiltersOpen: boolean
    readonly filterOptions: any
    readonly onChangeFilters: any
    readonly sx?: BoxProps
}

const FiltersDemandSideNav: FC<FiltersDemandSideNavProps> = ({ isFiltersOpen, filterOptions, onChangeFilters, sx }) => {

    const [creationDate, setCreationDate] = useState({ 'start': null, 'end': null });
    const [expirationDate, setExpirationDate] = useState({ 'start': null, 'end': null })

    return <Box
        sx={{
            ...sx,
            height: '100%',
            overflowY: 'auto',
            display: isFiltersOpen ? 'block' : 'none',
        }}
    >
        {
            filterOptions &&
            <Stack>
                <AccordionFilters
                    title={filterOptions['portal_de_origen']['label']}
                    endpoint={'portal_de_origen'}
                    children={
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            id="portal_de_origen"
                            options={filterOptions['portal_de_origen']['items'].map((label: string) => ({ label }))}
                            noOptionsText='No se encontraron resultados'
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event: any, newValues: Array<string> | null) => {
                                onChangeFilters(newValues?.map((item: any) => (item.label)), 'portal_de_origen')
                            }}
                        />
                    }
                />
                <AccordionFilters
                    title={filterOptions['nombre_cliente']['label']}
                    endpoint={'nombre_cliente'}
                    children={
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            id="nombre_cliente"
                            options={filterOptions['nombre_cliente']['items'].map((label: string) => ({ label }))}
                            noOptionsText='No se encontraron resultados'
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event: any, newValues: Array<string> | null) => {
                                onChangeFilters(newValues?.map((item: any) => (item.label)), 'nombre_cliente')
                            }}
                        />
                    }
                />
                <AccordionFilters
                    title={filterOptions['status']['label']}
                    endpoint={'status'}
                    children={
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            id="combo_box_firm"
                            options={filterOptions['status']['items'].map((label: OpportunityStatusType) => ({ label: parseStatus(label) }))}
                            noOptionsText='No se encontraron resultados'
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event: any, newValues: Array<string> | null) => {
                                onChangeFilters(newValues?.map((item: any) => (getStatusID(item.label))), 'status')
                            }}
                        />
                    }
                />
                <AccordionFilters
                    title={'ID Oportunidad'}
                    endpoint={'id_opportunity'}
                    children={
                        <Stack
                            direction='row'
                            alignItems='center'
                            component='form'
                            onSubmit={(event: any) => { event.preventDefault(); onChangeFilters(event.currentTarget.elements.idOpportunity.value, 'id_oportunidad') }}
                        >
                            <TextField id="idOpportunity" variant="standard" fullWidth />
                            <Fab color="primary" aria-label="edit" size='small' type='submit'
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                    minHeight: '24px',
                                }}
                            >
                                <KeyboardArrowRightIcon />
                            </Fab>
                        </Stack>
                    }
                />
                <AccordionFilters
                    title={'Fecha de Creación'}
                    endpoint={'create_date'}
                    children={
                        <DatePickerRange
                            dates={creationDate}
                            onChangeDate={(newDate) => { setCreationDate(newDate) }}
                            onClickFab={() => onChangeFilters(creationDate, 'create_date')}
                        />
                    }
                />
                <AccordionFilters
                    title={'Fecha de Vencimiento'}
                    endpoint={'expiration_date'}
                    children={
                        <DatePickerRange
                            dates={expirationDate}
                            onChangeDate={setExpirationDate}
                            onClickFab={() => onChangeFilters(expirationDate, 'expiration_date')}
                        />
                    }
                />

                <AccordionFilters
                    title={'Total SKU'}
                    endpoint={'total_sku'}
                    children={
                        <Box>
                            <Stack
                                component='form'
                                onSubmit={(event: any) => { event.preventDefault(); onChangeFilters({ 'min': event.currentTarget.elements.minTotalSKU.value == "" ? 0 : event.currentTarget.elements.minTotalSKU.value, 'max': event.currentTarget.elements.maxTotalSKU.value }, 'totalSKU') }}
                            >
                                {/*<Button variant='text' onClick={() => { onChangeFilters({ 'min': '0', 'max': '2' }, 'totalSKU') }}> Hasta 2 SKUs </Button>
                                <Button variant='text' onClick={() => { onChangeFilters({ 'min': '3', 'max': '5' }, 'totalSKU') }}> Entre 3 y 5 SKUs </Button>
                                <Button variant='text' onClick={() => { onChangeFilters({ 'min': '6', 'max': null }, 'totalSKU') }}> Más de 6 SKUs </Button>*/}
                                <Stack
                                    direction='row'
                                    spacing={2}
                                    alignItems='center'
                                >
                                    <TextField type='number' id='minTotalSKU' label="Min" variant="standard" />
                                    <TextField type='number' id='maxTotalSKU' label="Max" variant="standard" />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            margin: 'auto',
                                            height: '100%',
                                        }}
                                    >
                                        <Fab color="primary" aria-label="edit" size='small' type='submit'
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                                minHeight: '24px',
                                            }} >
                                            <KeyboardArrowRightIcon />
                                        </Fab>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>
                    }
                />
            </Stack>
        }
    </Box>
}

export default FiltersDemandSideNav;