import { Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { FC, useEffect, MouseEvent, ChangeEvent, useState, useCallback, useMemo } from 'react'
import { useStoreState, useStoreActions } from '../../store';
import { CircularLoader } from '../../components/circular-loader';
import { BinnacleListTable } from '../../components/list-table-binnacle';
import StateRequestScreen from '../../components/state-request-screen';
import { useIsMount } from '../../hooks/use-is-mount';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

import Chip from '@mui/material/Chip';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import IconButton from '@mui/material/IconButton';
import FiltersSideNav from '../../components/filers-side-nav';

interface BinnaclesProps {

}

export const Binnacles: FC<BinnaclesProps> = () => {

    const requestAllPageData = useStoreActions((actions: any) => actions.binnacle.requestAllPageData);
    const requestBinnacleInPage = useStoreActions((actions: any) => actions.binnacle.requestBinnacleInPage);
    const setRowsPerPage = useStoreActions((actions: any) => actions.binnacle.setRowsPerPage);
    const setPage = useStoreActions((actions: any) => actions.binnacle.setPage);
    const setRequestSearchState = useStoreActions((actions: any) => actions.binnacle.setRequestSearchState);
    const setBinnacleToSearch = useStoreActions((actions: any) => actions.binnacle.setBinnacleToSearch);

    const filterOptions = useStoreState((state: any) => state.binnacle.filterOptions);
    const requestSearchState = useStoreState((state: any) => state.binnacle.requestSearchState);
    const binnacleToSearch = useStoreState((state: any) => state.binnacle.binnacleToSearch);
    const binnaclesInPage = useStoreState((state: any) => state.binnacle.binnaclesInPage);
    const page = useStoreState((state: any) => state.binnacle.page);
    const rowsPerPage = useStoreState((state: any) => state.binnacle.rowsPerPage);

    const emptyFilters = {
        getFirms: [],
        getSup: [],
        getCatRot: [],
        getItemName: [],
        binnacleToSearch: [],
    }
    const [activeFilters, setActiveFilters] = useState<Record<string, any>>(emptyFilters)

    const [binnaclesView, setBinnacleView] = useState<'list' | 'grid'>('list');
    const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

    const isMount = useIsMount();

    const onChangeFilters = useCallback((data: Record<string, any>, checked: boolean) => {
        const { item, endpoint } = data
        setRequestSearchState('loading')
        setPage(1)

        if (endpoint === 'binnacleToSearch') {
            if (checked) {
                setActiveFilters((prevState) => ({ ...prevState, [endpoint]: [item] }))
            } else {
                setActiveFilters((prevState) => ({ ...prevState, [endpoint]: [] }))
                setBinnacleToSearch(null)
            }
        } else {
            if (checked) {
                setActiveFilters((prevState) => ({ ...prevState, [endpoint]: [...prevState[endpoint], item] }))
            } else {
                setActiveFilters((prevState) => ({ ...prevState, [endpoint]: prevState[endpoint].filter((current_item: Record<string, any>) => (current_item.id !== item.id)), }))
            }
        }
    }, [setRequestSearchState, setPage, setActiveFilters, setBinnacleToSearch])

    const limpiarFiltros = () => {
        setActiveFilters(emptyFilters)
        setBinnacleToSearch(null)
        setPage(1)
    }

    useEffect(() => {
        requestAllPageData()
    }, [requestAllPageData]);

    useEffect(() => {
        if (binnacleToSearch) {
            onChangeFilters({ item: { value: binnacleToSearch, id: binnacleToSearch }, endpoint: 'binnacleToSearch' }, true)
        }
    }, [binnacleToSearch, onChangeFilters]);

    useEffect(() => {
        // if (isMount) {
        //     let filters: Record<string, string> = {}
        //     Object.keys(activeFilters).forEach(function (key, index) {
        //         filters[key] = activeFilters[key].map((item: any) => item.id);
        //     });
        //     requestBinnacleInPage({ filters })
        // }
        requestAllPageData()
    }, [rowsPerPage, page]);

    useEffect(() => {
        if (isMount) {
            let filters: Record<string, string> = {}
            Object.keys(activeFilters).forEach(function (key, index) {
                filters[key] = activeFilters[key].map((item: any) => item.id);
            });
            requestBinnacleInPage({ filters, description: binnacleToSearch })
        }
    }, [activeFilters]);

    const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setRequestSearchState('loading')
        setPage(newPage + 1)
    }

    const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setRequestSearchState('loading')
        setRowsPerPage(event.target.value)
    }

    const handleBinnacleViewChange = (event: React.MouseEvent<HTMLElement>, nextView: 'list' | 'grid') => {
        nextView && setBinnacleView(nextView);
    };

    const submitHandler = useCallback((e: any) => {
        e.preventDefault()
        const value = e.target.item_name_search.value || ''
        onChangeFilters({ item: { value, id: value }, endpoint: 'getItemName' }, true)
    }, [onChangeFilters])

    const areActiveFilters = useMemo(
        () => Object.keys(activeFilters).some((key) => activeFilters[key].length > 0)
        , [activeFilters])

    if (requestSearchState == 'loading') return (
        <CircularLoader state={requestSearchState} />
    )

    if (requestSearchState == 'error' || !binnaclesInPage) return (
        <Box sx={{ width: '40%', display: 'flex', alignSelf: 'center', margin: 'auto', height: '100%' }}>
            <StateRequestScreen text='No es posible listar la bitácora estos momentos' status='error' />
        </Box>
    )


    return (
        <Box
            sx={{
                height: '100%',
            }}
        >

            {
                <Stack
                    gap={2}
                    direction='row'
                    justifyContent="space-around"
                    sx={{
                        width: '100%',
                        padding: '20px 10px',
                        maxHeight: { md: '650px', xl: '750px' },
                    }}
                >
                    {/* <FiltersSideNav
                        isFiltersOpen={isFiltersOpen}
                        filterOptions={filterOptions}
                        activeFilters={activeFilters}
                        submitHandler={submitHandler}
                        onChangeFilters={onChangeFilters}
                        sx={{
                            minWidth: '20%'
                        }}
                    /> */}
                    <Box
                        sx={{
                            maxHeight: '100%',
                            overflowY: 'auto',
                            backgroundColor: 'white',
                            padding: '10px',
                            borderRadius: '10px'
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                                marginBottom: '10px'
                            }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <IconButton
                                    aria-label="filtrar"
                                    sx={{
                                        borderRadius: '5px'
                                    }}
                                    onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                                >
                                    <FilterAltIcon />
                                    <Typography variant='body1' >Filtrar</Typography>
                                </IconButton>
                                <Box>
                                    {
                                        Object.keys(activeFilters).map((key: string) => (
                                            activeFilters[key].map((item: Record<string, any>, idx: number) => (
                                                <Chip
                                                    label={item.value}
                                                    color='primary'
                                                    variant="outlined"
                                                    key={item.id}
                                                    onDelete={() => onChangeFilters({ item, endpoint: key }, false)}
                                                />
                                            )
                                            )
                                        )
                                        )
                                    }
                                    {areActiveFilters && <Button variant='text' onClick={() => limpiarFiltros()}> Limpiar Filtros </Button>}
                                </Box>
                            </Stack>
                        </Stack>
                        <BinnacleListTable
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            headers={[
                                { id: 'id', label: 'ID', tooltip: "N/A" },
                                { id: 'iduser', label: 'Usuario', tooltip: "N/A" },
                                { id: 'action', label: 'Accion', tooltip: "N/A" },
                                { id: 'created_at', label: 'Fecha', tooltip: "N/A" }
                            ]}
                            items={binnaclesInPage['items']}
                            count={binnaclesInPage['total']}
                            page={page - 1}
                            rowsPerPage={rowsPerPage}
                        />
                    </Box>
                </Stack>
            }
        </Box>
    )
}
