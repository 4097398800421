import { useTheme } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, InputAdornment, SvgIcon, TextField, Typography } from "@mui/material"
import { Box, BoxProps, Stack } from "@mui/system"
import { FC } from "react"
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface AccordionFiltersProps {
    readonly title: string,
    readonly endpoint: string,
    readonly items: Array<Record<string, any>>
    readonly onChange: any
    readonly activeFilters: Record<string, any>
}

const AccordionFilters: FC<AccordionFiltersProps> = ({ title, endpoint, items, onChange, activeFilters }) => {
    const theme = useTheme();

    return (
        <Accordion
            id='accordion_no_header_padding'
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                {title}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    maxHeight: '300px',
                    overflowY: 'auto'
                }}
            >
                <FormGroup>
                    {
                        items.map((item, idx) => {
                            const isActive = activeFilters[endpoint]?.map((actFilter: any) => actFilter.id).includes(item.id)
                            // return <Box 
                            //   key={`${item.value}_${idx}`}
                            //   sx={{
                            //     verticalAlign: 'middle'
                            //   }}
                            //   >
                            //   <input
                            //     name={`${item.value}_${idx}`}
                            //     id={`${item.value}_${idx}`}
                            //     type="checkbox"
                            //     checked={isActive}
                            //     onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange({ item, endpoint }, event.target.checked)}
                            //     style={{
                            //       width: '18px',
                            //       height: '18px',
                            //       accentColor: 'red',
                            //       verticalAlign: 'middle', 
                            //       position: 'relative',
                            //     }}
                            //   />
                            //   <label 
                            //     htmlFor={`${item.value}_${idx}`}
                            //     style={{
                            //       verticalAlign: 'middle',
                            //       fontSize: '0.95rem'
                            //     }}
                            //   >
                            //     {item.value}
                            //   </label>
                            // </Box>

                            return <FormControlLabel
                                sx={{
                                    ...(isActive && {
                                        backgroundColor: theme.palette.action.selected,
                                        borderRadius: '10px',
                                    }),
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.selected,
                                        borderRadius: '10px',
                                    }
                                }}
                                key={idx}
                                control={
                                    <Checkbox
                                        sx={{
                                            padding: '5px'
                                        }}
                                        checked={isActive}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange({ item, endpoint }, event.target.checked)}
                                    />}
                                label={
                                    <Typography
                                        sx={
                                            {
                                                ...(isActive ? { 'fontWeight': 600 } : {})
                                            }
                                        }
                                        variant='body2'
                                    >
                                        {item.value}
                                    </Typography>}
                                name={item.value}
                            />
                        }
                        )
                    }
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    )
}

interface FiltersSideNavProps {
    readonly isFiltersOpen: boolean
    readonly filterOptions: any
    readonly submitHandler: (e: any) => void
    readonly onChangeFilters: any
    readonly activeFilters: any
    readonly sx?: BoxProps
}

const FiltersSideNav: FC<FiltersSideNavProps> = ({ isFiltersOpen, filterOptions, submitHandler, onChangeFilters, activeFilters, sx }) => {

    return <Box
        sx={{
            ...sx,
            maxHeight: '100%',
            overflowY: 'auto',
            display: isFiltersOpen ? 'block' : 'none',
        }}
    >
        {
            filterOptions &&
            <Stack>
                <Box component="form" onSubmit={submitHandler} sx={{ width: '100%', padding: '10px', backgroundColor: 'white', borderRadius: '10px', marginBottom: '5px' }}>
                    <TextField
                        fullWidth
                        name="item_name_search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SvgIcon>
                                        <SearchMdIcon />
                                    </SvgIcon>
                                </InputAdornment>
                            )
                        }}
                        label="Filtrar por nombre de producto"
                    />
                </Box>
                {
                    filterOptions.map(
                        (filterOption: Record<string, any>, idx: number) =>
                            <AccordionFilters
                                title={filterOption['label']}
                                endpoint={filterOption['endpoint']}
                                items={filterOption['items']}
                                onChange={onChangeFilters}
                                activeFilters={activeFilters}
                                key={idx}
                            />
                    )
                }
            </Stack>
        }
    </Box>
}

export default FiltersSideNav;