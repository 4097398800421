import { FC } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { format } from 'date-fns';

interface DatePickerProps {
    readonly date: any
    readonly setDate: any
    readonly label: string
    readonly inputProps?: any
    readonly sx?: any
    readonly error?: boolean
}

const DatePicker : FC<DatePickerProps> = ({date, setDate, label, inputProps, sx, error}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} >
            <TextField
                error={error}
                helperText={error && "Campo Obligatorio"}
                id="date"
                label={label}
                type="date"
                size='small'
                defaultValue={date}
                onChange={(e) => setDate(e.target.value)}
                inputProps={inputProps}
                InputLabelProps={{
                    shrink: true,
                }}
                sx={{...sx}}
            />
        </LocalizationProvider>
    )
}


export default DatePicker;